<template>
  <div>
    <validation-observer ref="simpleRules">
      <!-- <this-header /> -->
      <div class="anan-set-nav-conttainer mt-150">
        <div class="row">
          <div class="col-md-2 col-lg-2 col-sm-12">
            <side-bar class="d-none d-md-block" />
          </div>

          <div class="col-md-10 col-lg-10 col-sm-12">
            <b-overlay
              :show="showOver"
              variant="transparent"
              opacity="0.99"
              blur="5px"
              rounded="sm"
            >
              <template #overlay>
                <div class="text-center">
                  <p id="cancel-label">
                    กรุณารอสักครู่...
                  </p>
                </div>
              </template>
            <div>
              <b-button
                class="btn btn-gradient-primary mb-1 mr-50"
                @click="$router.go(-1)"
              >
                <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
              </b-button>
              <b-button
                class="btn btn-gradient-success mb-1 ml-25 mr-50"
                :disabled="Isdisabled"
                @click="Submit()"
              >
                <i class="far fa-save mr-50" /> {{ $t('saveData') }}
              </b-button>

              <b-row class="match-height">
                <b-col md="6">

                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-50">
                        {{ $t('details') }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="basic-addon3"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('taobaoID') }}
                            </template>
                            <b-input-group class="input-group-merge">
                              <b-form-input
                                id="basic-addon3"
                                v-model="taobao_username"
                                type="text"
                                placeholder=""
                              />

                            </b-input-group>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-password-tao"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('taobaoPassword') }}
                            </template>

                            <b-input-group class="input-group-merge">
                              <b-form-input
                                id="h-password-tao"
                                v-model="taobao_password"
                                :type="passwordFieldTypeTao"
                                placeholder=""
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  :icon="passwordToggleIcon"
                                  class="cursor-pointer"
                                  @click="toggleTaoPasswordVisibility"
                                />
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-id-alipay"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('alipayID') }}
                            </template>

                            <b-form-input
                              id="h-id-alipay"
                              v-model="alipay_username"
                              type="text"
                              placeholder=""
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-password-alipay"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('alipayPassword') }}
                            </template>

                            <b-input-group class="input-group-merge">
                              <b-form-input
                                id="h-password-alipay"
                                v-model="alipay_password"
                                :type="passwordFieldTypeAlipay"
                                placeholder=""
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  :icon="passwordToggleIcon2"
                                  class="cursor-pointer"
                                  @click="toggleAlipayPasswordVisibility"
                                />
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-password-pay"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('paymentCode') }}
                            </template>

                            <b-input-group class="input-group-merge">
                              <b-form-input
                                id="h-password-pay"
                                v-model="alipay_code"
                                :type="passwordFieldAlipay"
                                placeholder=""
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  :icon="passwordToggleIcon3"
                                  class="cursor-pointer"
                                  @click="AlipayPasswordVisibility"
                                />
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-file1"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('attachedFile') }}
                            </template>
                            <!-- <validation-provider
                              #default="{ errors }"
                              name="File"
                              rules="required"
                            > -->
                            <b-form-file
                              id="h-file1"
                              v-model="file_cus"
                              accept=".jpg, .png, .jpeg"
                              :placeholder="$t('attachFile')"
                              drop-placeholder="Drop file here..."
                              @change="fileImage"
                            />
                            <div class="mt-2 d-flex justify-content-left">
                              <span
                                v-if="imageOr"
                                class="preview-image-containerv2 mr-2 d-flex justify-content-center"
                              >
                                <img
                                  :src="imageOr"
                                  alt="Preview"
                                  class=""
                                  @click="showPicture(imageOr)"
                                >
                                <div class="preview-image-overlays p-0">
                                  <i
                                    class="fas fa-times-circle cursor-pointer"
                                    @click="deleteImageorder()"
                                  />
                                </div>
                              </span>
                            </div>
                            <!-- <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> -->
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-50">
                        {{ $t('rateInformation') }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="basic-addon3"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              <span class="text-danger">*</span> {{ $t('transferAmount') }}
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="price"
                              rules="required"
                            >
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="basic-addon3"
                                  v-model="price"
                                  type="number"
                                  placeholder=""
                                  :state="errors.length > 0 || price == 0 ? false:null"
                                />
                                <b-input-group-append is-text>
                                  ¥
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] ? this.$t('requiredField'): '' }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('exchangeRate') }}
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              ¥1 = {{ General.rate_payment }} ฿
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('maxExchangeAmount') }}
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              ¥ {{ Commas(sumitem = (Number(Balance.balance) / Number(General.rate_payment)) ) }}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('systemBalance') }}
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{ Commas(Balance.balance) }}  ฿
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-remark"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('remarks') }}
                            </template>
                            <b-form-textarea
                              id="h-remark"
                              v-model="remark"
                              type="text"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-table
                        responsive="sm"
                        :fields="fieldsRelevant"
                        :items="[General]"
                        show-empty
                      >
                        <template #empty="">
                          <b-table-empty-slot>
                            <div class="text-center my-2">
                              <img
                                src="@/assets/images/anan-img/svg/doc_empty.svg"
                                alt="empty"
                                height="60px"
                              >
                              <p class="text-muted">
                                No Data
                              </p>
                            </div>
                          </b-table-empty-slot>
                        </template>

                        <template #cell(rate)="data">
                          <!-- <span class="text-success"> -->
                          {{ data.item.rate_payment }}
                          <!-- </span> -->
                        </template>
                        <template #cell(min_price)="">
                          0
                          <!-- {{ data.item.rate_payment }} -->
                          <!-- <span class="text-success">
                            {{ data.value }}
                          </span> -->
                        </template>
                      </b-table>

                    </div>

                  </div>
                </b-col>
              </b-row>

              <!-- modal bank list -->
              <b-modal
                ref="modal-bank-list"
                :title="$t('selectChineseBankAccount')"
                size="lg"
                :ok-title="$t('key-124')"
                :cancel-title="$t('cancel')"
                @ok.prevent="$refs['modal-bank-list'].hide()"
              >

                Bank list
              </b-modal>
            </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </validation-observer>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormFile,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BOverlay,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import SideBar from '../component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    ValidationObserver,
    ValidationProvider,
    SideBar,
    // ThisHeader,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    VueImageLightboxCarousel,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      isActive: 'all',
      price: 0,
      password_tao: '',
      alipay_id: '',
      password_alipay: '',
      password_pay: '',
      Transaction: [
        {
          rate: '¥1 = 5.14 ฿',
          min_price: '¥ 0.00',
        },
      ],

      fileadmin: [],
      fileadmins: [],
      remark: null,
      General: [],
      Balance: [],
      taobao_username: null,
      taobao_password: null,
      alipay_username: null,
      alipay_password: null,
      alipay_code: null,
      file_cus: null,
      imageOr: null,
      sumitem: 0,
      passwordFieldTypeTao: 'password',
      passwordFieldTypeAlipay: 'password',
      passwordFieldAlipay: 'password',
      showOver: false, 
      Isdisabled: false,
      
    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'rate', label: this.$t('exchangeRate'), thStyle: { width: '50%' },
        },
        { key: 'min_price', label: this.$t('threshold'), thStyle: { width: '50%' } },
      ]
    },
    passwordToggleIcon() {
      return this.passwordFieldTypeTao === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIcon2() {
      return this.passwordFieldTypeAlipay === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIcon3() {
      return this.passwordFieldAlipay === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.getGeneral()
    this.getBalance()
  },
  methods: {
    togglePasswordVisibility(field) {
      // เช็คว่า field ที่ส่งมาเป็น field ที่ต้องการหรือไม่
      if (field === 'taobao') {
        this.passwordFieldTypeTao = this.passwordFieldTypeTao === 'password' ? 'text' : 'password'
      } else if (field === 'alipay') {
        this.passwordFieldTypeAlipay = this.passwordFieldTypeAlipay === 'password' ? 'text' : 'password'
      } else if (field === 'pay') {
        this.passwordFieldAlipay = this.passwordFieldAlipay === 'password' ? 'text' : 'password'
      }
    },
    toggleTaoPasswordVisibility() {
      this.togglePasswordVisibility('taobao') // เรียกใช้ฟังก์ชันโดยส่งชื่อ field ที่ต้องการ
    },
    toggleAlipayPasswordVisibility() {
      this.togglePasswordVisibility('alipay') // เรียกใช้ฟังก์ชันโดยส่งชื่อ field ที่ต้องการ
    },
    AlipayPasswordVisibility() {
      this.togglePasswordVisibility('pay') // เรียกใช้ฟังก์ชันโดยส่งชื่อ field ที่ต้องการ
    },
    async getGeneral() {
      try {
        const { data: res } = await this.$http.get('/general/getData')
        if (res.status) {
          this.General = res.data
          // console.log(this.General)
        }
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    async getBalance() {
      try {
        this.showOver = true
        const { data: res } = await this.$http.get('/Balance/getbalanceOne')
        if (res) {
          this.Balance = res
          this.showOver = false
        }
        // console.log(this.Balance)
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    Submit() {
      this.showOver = true
      this.Isdisabled = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.price > this.sumitem) {
            this.SwalError('จำนวนเงินในระบบไม่เพียงพอ')
            this.Isdisabled = false
            this.showOver = false
          } else if (this.price === 0) {
            this.SwalError('กรุณากรอกข้อมูลให้ครบถ้วน')
            this.Isdisabled = false
            this.showOver = false
          } else if (!this.alipay_username) {
            this.SwalError('กรุณากรอกไอดีอลีเพย์')
            this.Isdisabled = false
            this.showOver = false
          } else {
            const obj = {
              taobao_username: this.taobao_username,
              taobao_password: this.taobao_password,
              alipay_username: this.alipay_username,
              alipay_password: this.alipay_password,
              alipay_code: this.alipay_code,
              file_cus: this.imageOr,
              balance: (this.price * this.General.rate_payment),
              amount: this.price,
              rate: this.General.rate_payment,
              remark_cus: this.remark,
            }
            this.$http.post('/Alipayschina/Storedata', obj)
              .then(response => {
                if (response.data.status) {
                  this.Success(this.$t('key-260'))
                  this.$router.go(-1)
                  this.Isdisabled = false
                  this.showOver = false
                }
              })
              .catch(err => {
                this.SwalError(err.response.data.message)
                console.log(err)
                this.Isdisabled = false
                this.showOver = false
              })
          }
        } else {
          this.Isdisabled = false
          this.showOver = false
        }
      })
    },
    fileImage(event) {
      if (event) {
        this.file_cus = event.target.files
        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.file_cus[0]
        if (!filepath) {
          this.imageOr = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.imageOr = e.target.result
            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    deleteImageorder() {
      this.imageOr = null
      this.file_cus = null
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
